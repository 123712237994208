import {
  createPlugin,
  createComponentExtension,
} from '@backstage/core-plugin-api';

// ProjectBadgePlugin
export const GitlabProjectBadgesPlugin = createPlugin({
  id: 'project-badge-plugin',
});

export const GitlabProjectEmbededBadges = GitlabProjectBadgesPlugin.provide(
  createComponentExtension({
    name: 'GitlabProjectEmbededBadges',
    component: {
      lazy: () =>
        import('./GitlabProjectBadgesCard').then(
          m => m.GitlabProjectBadgesCard,
        ),
    },
  }),
);
